import {
  getCommonParamsOptions,
  getGlobalParamsOptionsAsync,
  getParamsOptionsAsync
} from '@/utils/common.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'sn',
      type: 'input',
      label: 'terminal.sn'
    },
    {
      field: 'merchantName',
      type: 'input',
      label: 'common.app-merchant-name'
    },
    {
      field: 'brandName',
      type: 'input',
      label: 'terminal.brand-name'
    },
    {
      field: 'modelId',
      type: 'select',
      label: 'file.model-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('terminalModel', {
          isNeedPreFix: false
        })
    },
    {
      field: 'online',
      type: 'select',
      label: 'Online Status',
      options: [
        {
          label: 'Online',
          value: 'true'
        },
        {
          label: 'Offline',
          value: 'false'
        }
      ]
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'user.tenant',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('terminal_status')
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.last-report-date',
      otherOptions: {
        type: 'daterange',
        format: 'YYYY-MM-DD',
        valueFormat: 'YYYY-MM-DD'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
